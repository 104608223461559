html {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-height: 100vh;
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  /* font-family also in theme and global component in storybook */
  font-family: 'Roboto';

  /* this will need to be updated by design */
  /* min-width: 768px; */
  height: 100vh;
  width: 100vw;
}

input,
textarea,
label,
button,
text,
a,
p {
  font-family: 'Roboto';
  border-radius: '8px';
}

#root {
  display: flex;
  flex-direction: row;
  height: 100vh;
  min-height: 100vh;
  width: 100vw;
}

[tabIndex='0'] {
  outline: none;
}